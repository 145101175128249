import React, { useEffect, useState } from 'react';
import EnhancedTable from '../components/EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedSchedulesApi,
  uploadToCSSMApi,
} from '../actions/selectedSchedules';
import {
  SELECTED_SCHEDULE_FIELDS,
  SELECTED_SCHEDULE_FIELDS_HEADER_MAPPING,
  SELECTED_SCHEDULE_CSV_HEADER,
} from '../../constants/selectedScheduleDataConfig';
import { Button, Icon } from '@amzn/awsui-components-react';
import { CSVLink } from 'react-csv';
import TableHeader from '../components/TableHeader';
import { clearAllBannerMessages } from '../actions/bannerMessages';
import UploadCSSMModal from '../components/UploadCSSMModal';
import { endTimeCreator } from '../../helpers/timeHelpers';
import {
  timeComparator,
  dateComparator,
} from '../../helpers/tableComparators.js';
const SelectedSchedulesPage = () => {
  const selectedSchedules = useSelector((state) => state.selectedSchedules);
  const selectedSchedulesLoading = useSelector(
    (state) => state.loading.selectedSchedulesLoading
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearAllBannerMessages());
    dispatch(getSelectedSchedulesApi());
  }, []);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [isUploadCSSMModalVisible, setIsUploadCSSMModalVisible] = useState(
    false
  );
  const headers = SELECTED_SCHEDULE_FIELDS.map(
    (field) => SELECTED_SCHEDULE_FIELDS_HEADER_MAPPING[field]
  );
  const specialSortableColumnsComparatorsObj = {
    dayOne: dateComparator('dayOne'),
    startTime: timeComparator('startTime'),
    lunchBreak1: timeComparator('lunchBreak1'),
    lunchBreak2: timeComparator('lunchBreak2'),
    shortBreak1: timeComparator('shortBreak1'),
    shortBreak2: timeComparator('shortBreak2'),
  };
  const attachEndTime = (schedules) => {
    schedules.map(
      (schedule) =>
        (schedule.endTime = endTimeCreator(
          schedule.startTime,
          schedule.durationInHours
        ))
    );
    return schedules;
  };
  const HeaderComponent = () => (
    <TableHeader
      title={'Selected Schedules'}
      onRefresh={() => dispatch(getSelectedSchedulesApi())}
      buttons={[
        <Button key="exportCSV">
          <CSVLink
            data={
              currentSelection.length === 0
                ? attachEndTime(selectedSchedules)
                : attachEndTime(currentSelection)
            }
            filename="selected_schedules.csv"
            headers={SELECTED_SCHEDULE_CSV_HEADER}
          >
            <span className="export-csv-btn">Export CSV</span>
          </CSVLink>
        </Button>,
        <Button
          key="CSSM"
          onClick={() => setIsUploadCSSMModalVisible(true)}
          disabled={true}
        >
          Upload to CSSM
        </Button>,
      ]}
    />
  );
  return (
    <>
      <EnhancedTable
        headers={headers}
        dataKeys={SELECTED_SCHEDULE_FIELDS}
        data={selectedSchedules}
        loading={selectedSchedulesLoading}
        loadingText="Loading selected schedules"
        enableAction={true}
        currentSelection={currentSelection}
        setCurrentSelection={setCurrentSelection}
        HeaderComponent={HeaderComponent}
        selectionType="multi"
        specialSortableColumnsComparatorsObj={
          specialSortableColumnsComparatorsObj
        }
        showTimeZoneMapping={true}
      />
      <UploadCSSMModal
        candidateIds={currentSelection.map((el) => el.candidateId)}
        isModalVisible={isUploadCSSMModalVisible}
        setIsModalVisible={setIsUploadCSSMModalVisible}
      />
    </>
  );
};

export default SelectedSchedulesPage;
