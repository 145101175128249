export const SELECTED_SCHEDULE_FIELDS = [
  'candidateId',
  'candidateEmail',
  'firstName',
  'lastName',
  'classId',
  'dayOne',
  'language',
  'ouId',
  'site',
  'startTime',
  'durationInHours',
  'workGroup',
  'lunchBreak1',
  'lunchBreak2',
  'shortBreak1',
  'shortBreak2',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const SELECTED_SCHEDULE_FIELDS_HEADER_MAPPING = {
  candidateId: 'Candidate Id',
  candidateEmail: 'Email',
  classId: 'Class Id',
  dayOne: 'Day One',
  firstName: 'First Name',
  lastName: 'Last Name',
  language: 'Language',
  ouId: 'OU',
  site: 'Site',
  startTime: 'Start Time',
  durationInHours: 'Duration In Hours',
  workGroup: 'Work Group',
  lunchBreak1: 'Lunch Break 1',
  lunchBreak2: 'Lunch Break 2',
  shortBreak1: 'Short Break 1',
  shortBreak2: 'Short Break 2',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
};

export const SELECTED_SCHEDULE_CSV_HEADER = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Candidate ID', key: 'candidateId' },
  { label: 'Class ID', key: 'classId' },
  { label: 'Site', key: 'site' },
  { label: 'OU', key: 'ouId' },
  { label: 'Workgroup', key: 'workGroup' },
  { label: 'Lang', key: 'language' },
  { label: 'Start', key: 'startTime' },
  { label: 'End', key: 'endTime' },
  { label: 'Su', key: 'sunday' },
  { label: 'Mo', key: 'monday' },
  { label: 'Tu', key: 'tuesday' },
  { label: 'We', key: 'wednesday' },
  { label: 'Th', key: 'thursday' },
  { label: 'Fr', key: 'friday' },
  { label: 'Sa', key: 'saturday' },
  { label: 'BR1', key: 'shortBreak1' },
  { label: 'BR2', key: 'shortBreak2' },
  { label: 'LunchBreak1', key: 'lunchBreak1' },
  { label: 'LunchBreak2', key: 'lunchBreak2' },
  { label: 'Start Date', key: 'dayOne' },
];
