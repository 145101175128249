export const endTimeCreator = (startTime, durationInHours) => {
  const [hours, minutes] = startTime.split(':');
  const outputMinutes = Number(minutes) + (durationInHours % 1) * 60;
  return [
    Number(hours) +
      Math.floor(durationInHours) +
      Math.floor(outputMinutes / 60),
    outputMinutes % 60,
  ].join(':');
};

const dateRegex = new RegExp(
  '(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)'
);

export const dateValidator = (date) => {
  return dateRegex.test(date);
};
