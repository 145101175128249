import React, { useState, useEffect } from 'react';
import { Button, Modal } from '@amzn/awsui-components-react/polaris';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { uploadToCSSMApi } from '../actions/selectedSchedules';
import { FormField, Input } from '@amzn/awsui-components-react/polaris';
import { get } from 'lodash';
const UploadCSSMModal = ({
  candidateIds,
  isModalVisible,
  setIsModalVisible,
}) => {
  const dafaultCSSMInfo = {
    effectiveStartDate: '',
    effectiveEndDate: '',
    site: '',
    comment: '',
    approvedBy: '',
  };
  const [cssmInfo, setCSSMInfo] = useState(dafaultCSSMInfo);
  const dispatch = useDispatch();
  const onClose = () => {
    setIsModalVisible(false);
  };
  const onSubmit = () => {
    dispatch(uploadToCSSMApi({ ...cssmInfo, candidateIds }));
    setCSSMInfo(dafaultCSSMInfo);
    setIsModalVisible(false);
  };
  const modalHeaderText = 'Upload to CSSM';
  const modalCancelText = 'Cancel';
  const modalSubmitText = 'Ok';
  return (
    <>
      <Modal
        onDismiss={onClose}
        visible={isModalVisible}
        size="medium"
        footer={
          <>
            <Button onClick={onClose}>{modalCancelText}</Button>
            <Button variant="primary" onClick={onSubmit}>
              {modalSubmitText}
            </Button>
          </>
        }
        header={modalHeaderText}
      >
        <div id="CSSMForm">
          <FormField label="List of Candidate Id" key="candidateIds">
            <Input id="candidateIds" value={candidateIds.join(',')} disabled />
          </FormField>
          <FormField label="Effective Start Date" key="effectiveStartDate">
            <Input
              id="effectiveStartDate"
              value={cssmInfo.effectiveStartDate}
              placeholder="YYYY-MM-DD. For example, 2020-12-24"
              onChange={(val) => {
                setCSSMInfo({
                  ...cssmInfo,
                  effectiveStartDate: val.detail.value,
                });
              }}
            />
          </FormField>
          <FormField label="Effective End Date" key="effectiveEndDate">
            <Input
              id="effectiveEndDate"
              value={cssmInfo.effectiveEndDate}
              placeholder="YYYY-MM-DD. For example, 2020-12-24"
              onChange={(val) => {
                setCSSMInfo({
                  ...cssmInfo,
                  effectiveEndDate: val.detail.value,
                });
              }}
            />
          </FormField>
          <FormField label="Site" key="site">
            <Input
              id="site"
              value={cssmInfo.site}
              onChange={(val) => {
                setCSSMInfo({
                  ...cssmInfo,
                  site: val.detail.value,
                });
              }}
            />
          </FormField>
          <FormField label="Comment" key="comment">
            <Input
              id="comment"
              value={cssmInfo.comment}
              onChange={(val) => {
                setCSSMInfo({
                  ...cssmInfo,
                  comment: val.detail.value,
                });
              }}
            />
          </FormField>
          <FormField label="Approved By" key="approvedBy">
            <Input
              id="approvedBy"
              value={cssmInfo.approvedBy}
              placeholder="Your Amazon Login Alias"
              onChange={(val) => {
                setCSSMInfo({
                  ...cssmInfo,
                  approvedBy: val.detail.value,
                });
              }}
            />
          </FormField>
        </div>
      </Modal>
    </>
  );
};
UploadCSSMModal.propTypes = {
  candidateIds: PropTypes.array,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
};
export default UploadCSSMModal;
