import {
  GET_SELECTED_SCHEDULES_ENDPOINT,
  UPLOAD_TO_ENDPOINT,
  headers,
} from '../../constants/apiConfigs';
import {
  ADD_SELECTED_SCHEDULES,
  SELECTED_SCHEDULES_ARE_LOADING,
} from './types';
import axios from 'axios';
import { get } from 'lodash';
import { showSuccessBanner, showErrorBanner } from './bannerMessages';

export function addSelectedSchedules(selectedSchedules) {
  return {
    type: ADD_SELECTED_SCHEDULES,
    payload: selectedSchedules,
  };
}

export function selectedSchedulesAreLoading(isLoading) {
  return {
    type: SELECTED_SCHEDULES_ARE_LOADING,
    payload: isLoading,
  };
}

export function getSelectedSchedulesApi() {
  return async (dispatch) => {
    dispatch(selectedSchedulesAreLoading(true));
    return axios
      .get(GET_SELECTED_SCHEDULES_ENDPOINT, {
        headers: await headers(),
      })
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) =>
        dispatch(addSelectedSchedules(get(res, 'data.selectedSchedules', [])))
      )
      .catch((e) => {
        dispatch(
          showErrorBanner(
            'There was an error getting the selected schedules from the server'
          )
        );
      })
      .then(() => dispatch(selectedSchedulesAreLoading(false)));
  };
}

export function uploadToCSSMApi(cssmInfo) {
  return async (dispatch) => {
    return axios
      .post(
        UPLOAD_TO_ENDPOINT,
        {
          ...cssmInfo,
        },
        {
          headers: await headers(),
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then((res) =>
        dispatch(showSuccessBanner('Schedules have been uploaded to CSSM'))
      )
      .catch((e) => {
        dispatch(showErrorBanner('There was an error during the uploading'));
      });
  };
}
